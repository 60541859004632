/* Estilos principais para a página de sucesso */
.success-page {
  background-color: var(--cinza-principal);
  padding: 40px 0;
  font-family: "Arial", sans-serif;
}

.success-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.success-header {
  text-align: center;
  margin-bottom: 40px;
}

.success-title {
  font-size: 36px;
  color: var(--cor-principal);
  font-weight: bold;
}

.success-description {
  font-size: 18px;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

/* Layout das informações do pedido */
.order-info-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

/* Coluna da esquerda - 3 cards */
.order-info-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.order-info-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-info-card h3 {
  font-size: 20px;
  color: var(--cor-principal);
  margin-bottom: 10px;
}

.order-info-card ul {
  list-style: none;
  padding: 0;
}

.order-info-card ul li {
  font-size: 16px;
  color: #555;
}

.order-info-card ul li strong {
  font-weight: bold;
}

/* Coluna da direita - Card grande */
.order-info-right {
  flex: 1.5;
  display: flex;
  justify-content: center;
}

.order-items-card {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.order-items-card h3 {
  font-size: 20px;
  color: var(--cor-principal);
  margin-bottom: 10px;
}

.order-items-card ul {
  list-style: none;
  padding: 0;
}

.order-items-card ul li {
  margin-bottom: 15px;
}

.order-items-card ul li p {
  font-size: 16px;
  color: #555;
}

/* Estilos dos botões */
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.button-primary {
  background-color: var(--cor-principal);
  color: #fff;
}

.button-primary:hover {
  background-color: #9b0937;
}

.button-secondary {
  background-color: #f1f1f1;
  color: var(--cor-principal);
  border: 2px solid var(--cor-principal);
}

.button-secondary:hover {
  background-color: #e0e0e0;
}

.productDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
}

.productDisplay img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  padding: 10px;
}

/* Responsividade */
@media (max-width: 1200px) {
  .success-container {
    padding: 20px;
  }

  .success-title {
    font-size: 32px;
  }

  .success-description {
    font-size: 16px;
  }

  .order-info-wrapper {
    flex-direction: column;
    gap: 40px;
  }

  .order-info-left,
  .order-info-right {
    flex: 1;
  }

  .order-items-card {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .success-title {
    font-size: 28px;
  }

  .success-description {
    font-size: 14px;
  }

  .order-info-card h3 {
    font-size: 18px;
  }

  .order-info-card ul li {
    font-size: 14px;
  }

  .action-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .button {
    width: 100%;
    text-align: center;
  }

  .order-info-left {
    flex-direction: column;
  }

  .order-info-right {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .success-title {
    font-size: 24px;
  }

  .success-description {
    font-size: 12px;
  }

  .order-info-card h3 {
    font-size: 16px;
  }

  .order-info-card ul li {
    font-size: 12px;
  }

  .order-info-wrapper {
    padding: 10px;
  }

  .order-items-card {
    max-width: 100%;
    padding: 15px;
  }

  .button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .productDisplay img {
    width: 60px;
    height: 60px;
  }
}