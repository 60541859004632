/* Container principal para a Política de Privacidade */
.faq-container {
  max-width: 1000px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Estilo para o cabeçalho da página */
.faq-header {
  text-align: center;
  margin-bottom: 30px;
}

.faq-title {
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: bold;
}

/* Estilo das seções da política */
.faq-section {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.faq-section h2 {
  font-size: 1.6rem;
  color: var(--cor-principal);
  margin-bottom: 15px;
  font-weight: bold;
}

.faq-section p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 15px;
}

/* Estilo do rodapé da página */
.faq-footer {
  text-align: center;
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

/* Responsividade */
@media (max-width: 1200px) {
  .faq-container {
    padding: 20px;
  }

  .faq-title {
    font-size: 2rem;
  }

  .faq-section h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 15px;
    margin: 20px auto;
  }

  .faq-title {
    font-size: 1.8rem;
  }

  .faq-section h2 {
    font-size: 1.2rem;
  }

  .faq-section p {
    font-size: 0.95rem;
  }

  .faq-footer {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .faq-container {
    padding: 10px;
    margin: 10px;
  }

  .faq-title {
    font-size: 1.6rem;
  }

  .faq-section h2 {
    font-size: 1.1rem;
  }

  .faq-section p {
    font-size: 0.9rem;
  }

  .faq-footer {
    font-size: 0.7rem;
  }
}
