
.orders-page .page-title {
    color: #be0a45;
    /* Cor principal */
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
}

.orders-page {
    background-color: #ffffff;
    /* Fundo branco para a tabela */
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.orders-page .table {
    width: 100%;
    border-collapse: collapse;
}

.alertIsEditing {
    width: 1200px;
    margin: 24px auto;
    background-color: #be0a45;
    color: white;
    border-radius: 12px;
    padding: 12px;
}

.orders-page th,
.orders-page td {
    padding: 12px;
    text-align: left;
}

.orders-page th {
    background-color: #f2f2f2;
    /* Cor de fundo neutra para cabeçalho */
    color: #333;
    /* Cor do texto do cabeçalho */
}

.orders-page tr:nth-child(even) {
    background-color: #f9f9f9;
    /* Cor de fundo alternada para linhas */
}

.orders-page tr:hover {
    background-color: #f1f1f1;
    /* Cor de fundo ao passar o mouse */
}

.orders-page .action-button {
    background-color: #be0a45;
    /* Cor principal para botões */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.orders-page .action-button:hover {
    background-color: #a50a35;
    /* Cor mais escura ao passar o mouse */
}