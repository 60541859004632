.subcategorys-section-wrapper {
    margin: auto;
    justify-content: center;
    width: 1400px;
    padding: 32px 0px;
}

.subcategory-section-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 900px) {
    .subcategory-section-content {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
    }

    .subcategorys-section-wrapper {
        width: 100%;
    }
}