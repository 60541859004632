.product-card-wrapper {
  width: 297px;
  position: relative;
  text-align: center;
}

.product-card-wrapper img {
  width: 100%;
  height: 297px;
  object-fit: cover;
  background-color: var(--cinza-principal);
}

.favorite-button-container {
  position: absolute;
  top: 5px;
  right: 10px;
  fill: white;
  background-color: white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin: auto;
  justify-content: center;
}

.product-title {
  font-size: 14px;
  font-weight: 600;
}

.product-subtitle {
  font-size: 12px;
  margin: 16px 0px;
}

.product-card-footer {
  padding-top: 30px;
  padding-bottom: 60px;
}

@media screen and (max-width: 900px) {
  .product-card-wrapper {
    width: 157px;
  }

  .product-card-wrapper img {
    height: 157px;
  }
}