/* Estilos principais */
.account-wrapper {
    display: flex;
    min-height: 100vh;
}

.sidebarcontent-account {
    width: 250px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.user-photo {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.user-greeting p {
    font-size: 18px;
    font-weight: bold;
}

.navigation ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.navigation li {
    margin: 20px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.content {
    border: 1px solid #c9c6c6;
    width: 800px;
    height: 100%;
    padding: 40px;
    margin-left: 16px;
    border-radius: 4px;
}

.content h2 {
    font-size: 24px;
    color: #333;
    font-weight: 600;
}

.content p {
    font-size: 18px;
    color: #555;
}

/* Container geral da aba de pedidos */
.orders-content {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

/* Lista de pedidos */
.orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Card do pedido */
.order-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Cabeçalho do pedido */
.order-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.order-header h3 {
    font-size: 1.5rem;
    color: #333;
}

.order-header p {
    font-size: 1rem;
    color: #777;
}

/* Seção de informações do pedido */
.order-info {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Detalhes do pedido (data, pagamento, etc.) */
.order-details {
    width: 48%;
    font-size: 1rem;
    color: #555;
}

.order-details p {
    margin: 5px 0;
}

.order-details strong {
    color: #333;
}

/* Seção de produtos */
.order-items {
    width: 48%;
}

/* Card de item individual */
.order-item {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.order-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    background-color: #f0f0f0;
}

.item-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-details h5 {
    font-size: 1.2rem;
    margin: 0;
    color: #333;
}

.item-details p {
    font-size: 1rem;
    color: #666;
    margin: 5px 0;
}

.item-details strong {
    font-weight: bold;
}

/* Ajuste para os botões (se houver algum botão de ação, como "Ver mais", "Cancelar", etc.) */
.order-card .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.action-buttons button {
    background-color: #007BFF;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.action-buttons button:hover {
    background-color: #0056b3;
}

/* Estilo para o rodapé (informações de pagamento, endereço, etc.) */
.order-footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
    margin-top: 15px;
    font-size: 1rem;
    color: #555;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
    .account-wrapper {
        flex-direction: column;
        margin: auto;
    }

    .sidebarcontent-account {
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
    }

    .user-info {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 15px;
        width: 95%;
        margin-top: 16px;
    }

    .user-greeting p {
        font-size: 14px;
    }

    .navigation {
        width: 95%;
        margin: auto;
    }

    .navigation ul {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin: auto;
    }

    .navigation li {
        margin: 0;
        font-size: 14px;
        width: 100%;
        margin: auto;
        text-align: left;
    }

    .content {
        padding: 20px;
    }

    .content {
        width: 95%;
        margin: 0 auto;
    }
}

@media (max-width: 480px) {
    .content h2 {
        font-size: 20px;
    }

    .content p {
        font-size: 16px;
    }

    .sidebarcontent-account {
        width: 100%;
        padding: 5px;
    }

    .navigation ul {
        flex-direction: column;
        align-items: center;
    }

    .navigation li {
        margin: 10px 0;
    }
}
