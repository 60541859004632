.carousel-wrapper {
  max-height: 660px;
  height: 660px;
  width: 100%;
  background-color: var(--cinza-principal);
}

.carousel-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
