.category-list-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 26px;
}

.category-list-content-mobile {
    display: none;
}

.mySwiper {
    display: flex !important;
    align-items: center;
} 

@media screen and (max-width: 900px) {
    .category-list-content-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        gap: 26px;
    }

    .category-list-content {
        display: none;
    }
}