/* Estilos do container principal */
.contact-container {
  background-color: #fff;
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

/* Título */
.contact-container h1 {
  color: var(--cor-principal);
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

/* Descrição */
.contact-description {
  color: #666;
  font-size: 1rem;
  margin-bottom: 2rem;
}

/* Opções de contato */
.contact-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-principal);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.contact-item:hover {
  transform: translateY(-5px);
}

.contact-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  width: 100%;
}

.contact-icon {
  width: 50px;
  height: 50px;
  margin-right: 1.5rem;
  transition: transform 0.2s;
}

.contact-icon:hover {
  transform: scale(1.1);
}

.contact-text {
  text-align: left;
}

.contact-text h3 {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 600;
}

.contact-text p {
  font-size: 0.875rem;
  margin: 0;
  color: #ddd;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .contact-options {
    grid-template-columns: 1fr;
  }

  .contact-item {
    flex-direction: column;
    align-items: center;
  }

  .contact-item .contact-text {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .contact-container {
    padding: 2rem;
  }

  .contact-container h1 {
    font-size: 2rem;
  }

  .contact-description {
    font-size: 0.9rem;
  }

  .contact-item {
    padding: 1rem;
  }
}
