/* Títulos */
.page-title {
    font-size: 1.6rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 30px;
    text-align: center;
}

/* Wrapper para o conteúdo */
.content-wrapper {
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

/* Box de Fotos */
.photos-box {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 4px;
}

.photos-gallery {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.product-image,
.product-image-single {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.sizes-box {
    display: flex;
    gap: 4px;
}

.page-title {
    display: flex;
    align-items: center;
    gap: 24px;
}

/* Box de Informações */
.info-box {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* Formulário */
.product-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    flex-direction: column;
}

.form-row label {
    font-size: 1rem;
    font-weight: 500;
    color: #666;
    margin-bottom: 8px;
}

.form-row input {
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #333;
    transition: border 0.3s;
}

.form-row input:focus {
    border-color: #be0a45;
}

.form-actions {
    display: flex;
    justify-content: center;
}

.btn-save {
    background-color: #be0a45;
    color: white;
    padding: 12px 30px;
    font-size: 1.1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-save:hover {
    background-color: #9c0838;
}

/* Responsividade */
@media (max-width: 768px) {
    .content-wrapper {
        flex-direction: column;
        gap: 30px;
    }

    .photos-box,
    .info-box {
        flex: none;
        width: 100%;
    }

    /* Ajustar a largura das imagens nas galerias para telas pequenas */
    .photos-gallery {
        flex-direction: row;
        overflow-x: auto;
        gap: 10px;
    }

    .product-image {
        height: 150px;
        width: 150px;
    }

    /* Ajustar o título para uma largura mais adequada */
    .page-title {
        font-size: 1.4rem;
        text-align: left;
    }

    /* Ajustar o formulário e a largura dos campos */
    .product-form {
        padding: 15px;
    }

    .form-row label {
        font-size: 0.9rem;
        margin-bottom: 6px;
    }

    .form-row input {
        font-size: 0.95rem;
        padding: 10px;
    }

    .btn-save {
        font-size: 1rem;
        padding: 10px 20px;
    }

    /* Ajuste para o botão salvar */
    .form-actions {
        padding: 10px 0;
    }
}

.createNewCategory {
    background-color: var(--cor-principal);
    box-shadow: 1px 1px 5px gray;
    color: white;
    padding: 12px;
    width: 90%;
    margin: 12px auto;
    border-radius: 12px;
}

/* Responsividade extra para telas menores (máximo 480px) */
@media (max-width: 480px) {
    .page-title {
        font-size: 1.2rem;
        text-align: left;
        margin-bottom: 20px;
    }

    .product-image {
        height: 120px;
        width: 120px;
    }

    .product-image-single {
        height: 180px;
    }

    /* Ajuste adicional para os campos do formulário */
    .form-row input {
        font-size: 0.9rem;
        padding: 8px;
    }

    .btn-save {
        font-size: 1rem;
        padding: 8px 15px;
    }

    /* Ajustar os botões e inputs */
    .form-row label {
        font-size: 0.85rem;
    }
}