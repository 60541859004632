.section-component-wrapper {
    width: 1400px;
    margin: auto;
    justify-content: center;
}

.section-component-title {
    text-align: center;
    margin: 16px 0px;
    padding: 16px 0px;
}

.section-component-title h1 {
    font-size: 24px;
    font-weight: 700;
}

.section-component-title span {
    font-size: 14px;
    font-weight: 300;
}

@media screen and (max-width: 900px) {
    .section-component-wrapper {
        width: 100%;
    }

    .section-component-title {
        width: 90%;
        margin: auto;
        padding: 42px 0px;
    }
}