.config-section {
    background-color: #f8f8f8;
    padding: 40px 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.config-container {
    margin: 0 auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.config-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.config-item {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.config-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.config-icon {
    font-size: 2rem;
    color: var(--cor-principal);
    margin-right: 20px;
}

.config-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333333;
    flex-grow: 1;
}

.config-button {
    background-color: var(--cor-principal);
    color: white;
    font-size: 1rem;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.config-button:hover {
    background-color: var(--cor-principal);
}

@media screen and (max-width: 768px) {
    .config-container {
        padding: 20px;
    }

    .title {
        font-size: 2rem;
    }

    .config-item {
        padding: 12px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 1.6rem;
    }

    .config-button {
        width: 100%;
        padding: 12px;
    }
}